import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useTranslation } from "react-i18next";
import IUserWithResults from "../../../types/IUserWithResults";
import StatsResultItem from "../../../components/StatsResultItem";
import { R_TEST_VIEW } from "../../../config/routes";

export default function CompanyTestResultsList({
  results
}: Readonly<{ results: IUserWithResults[] | undefined }>) {
  const { t } = useTranslation();

  return (
    <ArrayLoadingSkeleton emptyText={t("Brak wyników do wyświetlenia")} data={results}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("Uczestnik")}</TableCell>
              <TableCell align="center">{t("Progres")}</TableCell>
              <TableCell align="center">{t("Prób")}</TableCell>
              <TableCell align="right">{t("Wyniki")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results?.map((result) => <StatsResultItem key={result.id} data={result} linkTo={R_TEST_VIEW} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </ArrayLoadingSkeleton>
  );
}
