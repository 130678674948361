import { Button, CircularProgress, Grid, styled, Typography } from "@mui/material";
import React, { ChangeEvent, ChangeEventHandler, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Stack } from "@mui/system";
import { uploadFile } from "../../../services/files.service";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});

const DocumentMedia = ({ mediaKey }: { mediaKey: string }) => {
  const [file, setFile] = useState<File | null | boolean>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleUpload: ChangeEventHandler<HTMLInputElement> = (event) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const file = event.target.files[0];
    if (file) {
      console.log("file", file);
      const uploadedFile = uploadFile(file)
      console.log("uploadedFile", uploadedFile)
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Stack direction="row" spacing={2} padding={2}>
            <Stack
              direction={"column"}
              justifyContent={"center"}
              // alignItems={"center"}
              sx={{ flexGrow: 1 }}
            >
              <Typography>Nazwa Pliku</Typography>
            </Stack>
            {!file && (
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Wgraj plik
                <VisuallyHiddenInput type="file" onChange={handleUpload} multiple />
              </Button>
            )}
            {file && <Button>Usuń</Button>}
            {file && <Button variant="contained">Pobierz</Button>}
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default DocumentMedia;
