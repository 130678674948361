import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PaperStepper from "../../../components/PaperStepper";
import { getCompanyCardDetails, getCompanyCardList } from "../../../services/company.service";
import { useTranslation } from "react-i18next";

const CompanyRidesCard = (): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [card, setCard] = useState<any>();//todo: add types
  const [cardUsers, setCardUSers] = useState<any>(); //todo: add types


  useEffect(() => {
    if (id) {
      getCompanyCardDetails(id).then(({ data }) => {
        setCard(data);
      });
      getCompanyCardList(id).then((data) => {
        setCardUSers(data.data);
      });
    }
  }, [id]);

  return (
    <Box>
      <PaperStepper steps={[t("Wybierz kartę"), t("Statystyki karty")]} step={1} />

      <Typography mt={2} mb={2} component="h2" variant="h6">
        {t("Karty")}
      </Typography>

      <Paper>
        {card === null && <LinearProgress />}
        {card && card.avg && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Statystyki")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t("Wyniki")}</TableCell>
                <TableCell align="right">
                  {t("Max")} - <strong>{card.max} %</strong>
                  <br />
                  {t("Średnia")} - <strong>{parseInt(card.avg)} % </strong>
                  <br />
                  {t("Min")} - <strong>{card.min === 0 ? t("Brak") : card.min + " %"}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Wszystkich uczestników")}</TableCell>
                <TableCell align="right">{card.usersAmount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        {card && card.avg === null && (
          <Typography component="h3">
            <Box p={2}>{t("Brak statystyk do wyświetlenia")}</Box>
          </Typography>
        )}
      </Paper>

      <Typography mt={2} mb={2} component="h2" variant="h6">
        {t("Ewaluacje uczestników")}
      </Typography>

      <Paper>
        {cardUsers === null && <LinearProgress />}
        {cardUsers?.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Imię i nazwisko")}</TableCell>
                <TableCell align="right">{t("Wynik")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cardUsers.map((user: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{user.user.name}</TableCell>
                  <TableCell align="right">
                    <strong>{user.result} %</strong>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography component="h3">
            <Box p={2}>{t("Brak wyników do wyświetlenia")}</Box>
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default CompanyRidesCard;
