import axios, { AxiosResponse } from "axios";

export const uploadFile = async (file: File): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(`/files/file`, formData, {
    headers: {
      // "Content-Type": "multipart/form-data"
    }
  });
};
